export default {
  investment: {
    p1: '您的條款的關係。',
    wrapper_title: '按照您想要的方式與我們合作',
    textare1: '在環球星匯，您不需要在在線經紀人和財富管理公司之間做出選擇。',    
    textare2: '無論您是自己投資，還是由顧問投資，還是兩者兼顧 - 我們都可以爲您提供支持。',
    info_title1: '投資選擇',
    info_span1: '投資產品的廣泛選擇，以幫助建立多元化的投資組合',
    info_title2: '專業交易服務',
    info_span2: '專業的技術爲廣大用戶提供強大的交易平臺',
    info_title3: '多元化的交易工具',
    info_span3: '提供多元化的交易功能和先進分析工具',
    info_title4: '卓越的服務',
    info_span4: '重視客戶的交易體驗，致力於爲客戶提供卓越的客戶服務和支持',
    info_title5: '資金安全',
    info_span5: '嚴格遵循監管方的客戶資金安全準則，設立資金隔離賬戶',
    info_title6: '客戶服務',
    info_span6: '從週一至週五爲您提供全天24小時的1對1的諮詢服務',
    footer_text1: '環球星匯以客戶爲本，交易平臺擁有個性化交易定製功能，專業團隊的市場分析和深度研究爲每位客戶提供最及時的報價和最快速的執行。我們既能爲經驗豐富的人提供豐富的服務，同時也能讓初學者覺得簡單易行。',
    footer_text2: '目前行業內的外匯公司總是不能帶給交易者滿意的服務。點差高, 滑點嚴重是普遍的現象。我們承諾堅持行業的透明性和職業道德，提供給投資者一個公平、透明、 誠信的交易環境，實現雙贏！'
  } 
}