<template>
  <div id="app">
    <router-view />
    
  </div>
</template>

<script>
import { Local } from '@/utils/storage';
export default {
  name: 'App',
  components: {
  },
  created() {
    document.title = this.$t('message.router.education');
    if (!Local.get('lang')) {
      Local.set('lang', {lang: 'zh'})
    }    
  },
  watch: {
		// 监听语言的变化
		'$i18n.locale'(newValue, oldValue) {
			this.$nextTick(() => {
        console.log('newValue, oldValue', newValue, oldValue);
				let webTitle = '';
				webTitle = this.$t('message.router.education')
				document.title = `${webTitle}`;
			});
    }
	},
}
</script>

<style>
</style>
