export default {
  contact: {
    p1: 'Knowledge empowers you',
    wrapper_title1: 'Cooperation mode',
    p2: 'GCG MARKETS will be happy to apply for partners to give tutorials on trading platforms, and provide the corresponding agent website to provide you with a range of free service support.',
    info_title1: 'Business partner',
    info_span1: 'To consult with existing or potential limited partners/investors',
    info_title2: 'Business support',
    info_span2: 'Ask for information on training + Sales + support programs',
    info_title3: 'Business plan',
    info_span3: 'Hope to establish self-owned brand community platform',
    email_btn: 'Please email: info@gcg-markets.com'
  }  
}