export default {
  investment: {
    p1: 'Your terms of the relationship.',
    wrapper_title: 'Work with us the way you want',
    textare1: "At GCG MARKETS, you don't need to choose between an online broker and a wealth manager.",
    textare2: 'Whether you invest on your own, with an adviser, or both - we can support you.',
    info_title1: 'Investment choice',
    info_span1: 'A wide selection of investment products to help build a diversified portfolio',
    info_title2: 'Professional trading services',
    info_span2: 'Professional technology for the majority of users to provide a powerful trading platform',
    info_title3: 'Diversified trading instruments',
    info_span3: 'Provides diversified trading functions and advanced analytical tools',
    info_title4: 'Excellent service',
    info_span4: "We value our customers' trading experience and are committed to providing outstanding customer service and support",
    info_title5: 'Fund security',
    info_span5: "Strictly follow the supervisor's customer funds security guidelines, set up segregated accounts",
    info_title6: 'Customer service',
    info_span6: 'A 1-on-1 consultation service is available 24 hours a day from Monday to Friday',
    footer_text1: 'GCG MARKETS is customer-oriented, the trading platform has personalized trade customization functions, market analysis and in-depth research of the professional team to provide the most timely quotes and the fastest execution for each client. We can provide rich services for experienced people, but also make it easy for beginners.',
    footer_text2: 'At present, the forex companies in the industry always fail to provide satisfactory service to traders. High spreads and serious slippage are common phenomena. We promise to adhere to the transparency and professional ethics of the industry, to provide investors with a fair, transparent and honest trading environment, to achieve a win-win situation!'
  }   
}