export default {
  contact: {
    p1: '知識賦予您力量',
    wrapper_title1: '合作模式',
    p2: '環球星匯將竭誠爲合作伙伴申請給予交易平臺的教程，並提供相應的代理商網站爲您提供一系列的免費服務支持。',
    info_title1: '合作伙伴',
    info_span1: '欲諮詢現有或潛在的有限合夥人/投資者的信息',
    info_title2: '業務支持',
    info_span2: '欲諮詢培訓＋銷售＋支持方案的信息',
    info_title3: '商業計劃',
    info_span3: '期望建立自主品牌社區平臺',
    email_btn: '請電郵至： info@gcg-markets.com'
  }  
}