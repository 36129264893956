export default {
  router: {
		home: '主頁',
		investment: '投資',
		education: '環球星匯',
		contactUs: '聯繫我們',
		login: '登入',
		download: '下載'
	},
	footer: {
		p1: '過去的表現並不擔保未來回報，亦不代表未來表現皆如此。切記投資將涉及風險。',
		emailTo: '電郵至: ',
		serviceEmail: '官方客服郵箱：',
		supportEmail: '技術支持郵箱：',
		copyright: '版權由 2016 Global Consulting Group Limited.  所有，侵權必究',
		div_p1: '保證金交易是一種高風險的投資活動。槓桿式場外交易的現貨外幣合約具有投機性和高風險性。它通常只適合那些能夠承受損失風險超過其保證金存款的人。鑑於這些風險，客戶在進行交易之前，應充分了解所涉及的交易性質和他們面臨的風險程度。場外外匯現貨交易並不適合部分客戶。客戶應根據其個人經驗、投資目標、資金來源和其他相關情況，仔細考慮此類交易的適用性。您應仔細瞭解與外匯交易相關的風險。如有任何疑問，請諮詢您的專業財務顧問。',
		div_p1_strong: '風險管理',
		div_p2: '環球星匯提供的相關信息及其鏈接以及來自其他來源的市場信息僅供參考服務之用。環球星匯不推薦來自任何來源或第三方交易分析和指導的財務建議。客戶應仔細考慮和分析相關信息或其他信息來源，以便做出客觀的分析和決策。請注意，過去的交易結果不是預測未來交易表現的基礎。環球星匯建議客戶在投資前應仔細瞭解相關交易知識以及經紀商和其他形式的服務提供商所展示的交易或投資建議。環球星匯網站引用的任何新聞、觀點、研究、數據或其他信息僅作爲客觀的市場評論，不構成任何投資或交易建議。環球星匯特此明確聲明，對於因客戶直接或間接使用上述任何信息而產生的交易損失或利潤，環球星匯概不負責。',
		div_p2_strong: '交易免責聲明',
	}
}