export default {
  education: {
    p1: 'Knowledge empowers you',
    wrapper_title: 'About GCG MARKETS',
    wrapper_title_span1: "GCG MARKETS (Global Consulting Group Limited) is a leading provider of international financial derivatives and related services, aiming to provide investors with access to the world's financial markets through regulation, extremely low trading spreads and outstanding customer support services. We are committed to creating a one-stop Forex trading platform where traders can trade all products on the global market. Ability to trade CFDS such as Forex, indices, commodities, futures through one account. Make convenience one of the key factors for traders to choose GCG MARKETS.",
    wrapper_title_span2: 'Over the years, we have accumulated enough experience to create a safe and transparent trading environment for investors interested in the global financial derivatives market. Combine with top liquidity providers to trade various financial derivatives in real time through online trading platforms, providing investors with highly competitive trading spreads and reducing risks in trading.',
    wrapper_title_span3: 'GCG MARKETS uses an advanced online trading system covering the professional Forex and CFD markets to help clients get fast and accurate quotes through their risk management system. GCG MARKETS team members have many years of trading and compliance experience, gained from both traders and sales of brokering industry knowledge, combined with keen market insight, so that we can understand the needs of clients in the ever-changing market and better accept the challenge, to provide traders with a safe, stable and transparent trading platform.',
    wrapper_title2: 'Supervision and security',
    wrapper_title2_span1: 'We attach great importance to the investment and capital security of users, GCG MARKETS is regulated and issued by MSB (MoneyServicesBusiness) financial license license (license number 31000280998905), strictly implement its capital risk management measures.',
    // wrapper_sub_title: '知識是明智的投資',
    p2: 'We always believe that the more you know, the better you will be. By combining easy-to-understand information with actionable insights, GCG MARKETS helps make markets seem less intimidating and more accessible.',
    info_title1: 'Beginner course',
    info_span1: 'Learn the basic concepts of Forex trading, an overview of the market and why you should be a part of it.',
    info_title2: 'Trading instrument',
    info_span2: "Get familiar with advanced strategies and Xtrade's trading tools to take your trading to the next level.",
    info_title3: 'Stocks and CFDS',
    info_span3: 'Explore the world of CFD trading: Information about the CFD market and market dynamics.',
  }  
}