export default {
  investment: {
    p1: '您的条款的关系。',
    wrapper_title: '按照您想要的方式与我们合作',
    textare1: '在环球星汇，您不需要在在线经纪人和财富管理公司之间做出选择。',
    textare2: '无论您是自己投资，还是由顾问投资，还是两者兼顾 - 我们都可以为您提供支持。',
    info_title1: '投资选择',
    info_span1: '投资产品的广泛选择，以帮助建立多元化的投资组合',
    info_title2: '专业交易服务',
    info_span2: '专业的技术为广大用户提供强大的交易平台',
    info_title3: '多元化的交易工具',
    info_span3: '提供多元化的交易功能和先进分析工具',
    info_title4: '卓越的服务',
    info_span4: '重视客户的交易体验，致力于为客户提供卓越的客户服务和支持',
    info_title5: '资金安全',
    info_span5: '严格遵循监管方的客户资金安全准则，设立资金隔离账户',
    info_title6: '客户服务',
    info_span6: '从周一至周五为您提供全天24小时的1对1的咨询服务',
    footer_text1: '环球星汇以客户为本，交易平台拥有个性化交易定制功能，专业团队的市场分析和深度研究为每位客户提供最及时的报价和最快速的执行。我们既能为经验丰富的人提供丰富的服务，同时也能让初学者觉得简单易行。',
    footer_text2: '目前行业内的外汇公司总是不能带给交易者满意的服务。点差高, 滑点严重是普遍的现象。我们承诺坚持行业的透明性和职业道德，提供给投资者一个公平、透明、 诚信的交易环境，实现双赢！'
  }  
}