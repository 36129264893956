export default {
	router: {
		home: 'Home',
		investment: 'Invest',
		education: 'GCG MARKETS',
		contactUs: 'Contact us',
		login: 'Log in',
		download: 'Download'
	},
	footer: {
		p1: 'Past performance is not a guarantee of future returns, nor is it indicative of future performance. Remember that investment involves risk.',
		emailTo: 'Email to: ',
		serviceEmail: 'Official customer service Email:',
		supportEmail: 'Technical support Email:',
		copyright: 'Copyright 2016 Global Consulting Group Limited. All rights reserved',
		div_p1: 'Margin trading is a high-risk investment activity. Leveraged OTC spot foreign currency contracts are speculative and highly risky. It is usually only suitable for those who can afford to lose more than their margin deposits. In view of these risks, clients should fully understand the nature of the transaction involved and the extent of the risk to which they are exposed before entering into a transaction. Otc Forex spot trading is not suitable for some clients. Customers should carefully consider the suitability of such transactions in light of their personal experience, investment objectives, sources of funds and other relevant circumstances. You should carefully understand the risks associated with forex trading. If you have any questions, please consult your professional financial advisor.',
		div_p1_strong: 'Risk management',
		div_p2: 'The relevant information provided by GCG Markets and its links, as well as market information from other sources, are for reference purposes only. GCG Markets does not recommend financial advice from any source or third party trading analysis and guidance. Customers should carefully consider and analyze relevant information or other sources of information in order to make objective analyses and decisions. Please note that past trading results are not a basis for predicting future trading performance. GCG Markets recommends that clients carefully understand the relevant trading knowledge and trading or investment advice presented by brokers and other forms of service providers before investing. Any news, opinions, research, data or other information quoted on the GCG Markets website is intended solely as objective market commentary and does not constitute any investment or trading advice. GCG Markets expressly disclaims responsibility for trading losses or profits arising from the direct or indirect use of any of the above information by clients.',
		div_p2_strong: 'Trading disclaimer',
	}
};