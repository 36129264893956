export default {
  education: {
    p1: '知识赋予您力量',
    wrapper_title: '关于环球星汇',
    wrapper_title_span1: '环球星汇（Global Consulting Group Limited）是一家领先的国际金融衍生品及相关服务供应商，宗旨是通过监管、极低的交易点差以及杰出的客户支持服务使投资者进入世界金融市场。我们致力于创建一站式的外汇交易平台，交易者可以在全球市场上交易所有产品。能够通过一个账户进行外汇，指数，商品，期货等差价合约交易。让便利性成为交易者选择環球星匯的关键因素之一。',
    wrapper_title_span2: '经过多年的深耕累积，足够丰富的经验为对全球金融衍生品市场感兴趣的投资人打造一个安全、透明的交易环境。与顶尖的流动性供应商强强联合，通过在线交易平台实时交易各种金融衍生品，为投资者提供极具竞争力的交易点差，降低交易中的风险。',
    wrapper_title_span3: '環球星匯采用先进的在线交易系统涵盖专业的外汇和差价合约市场，帮助客户通过风险管理体系获得快速准确的报价。環球星匯团队成员具备多年的交易和合规经验，从交易者和销售双方获得经纪商业界知识，结合敏锐的市场洞察力，使我们能够在不断变化的市场中了解客户的需求和更好的接受挑战，为交易者提供安全、稳定、透明的交易平台。',
    wrapper_title2: '监管与安全',
    wrapper_title2_span1: '我们十分重视用户的投资与资金安全，环球星汇由美国MSB（MoneyServicesBusiness）监管并颁发的金融牌照许可（许可证号为31000280998905），严格执行其资金风险管理措施。',
    // wrapper_sub_title: '知识是明智的投资',
    p2: '我们一直坚信您认知得越多，将成就更好的你。通过简易理解的信息与可执行的见解互相结合，环球星汇有助于使市场看起来不那么令人生畏，并更易被接受。',
    info_title1: '初学者课程',
    info_span1: '学习外汇交易的基本概念、这个市场的概括和您理应成为一份子的原因。',
    info_title2: '交易工具',
    info_span2: '熟悉先进的策略和Xtrade的交易工具， 使您的交易提升至另一个新层次。',
    info_title3: '股票和差价合约',
    info_span3: '探索差价合约交易的世界：差价合约市场的相关信息和市场动态。',
  }  
}