import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
import { i18n } from '@/i18n/index';

// 图标 font-awesome
import 'font-awesome/css/font-awesome.min.css';

import '@/assets/theme/index.scss';

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
