export default {
  education: {
    p1: '知識賦予您力量',
    wrapper_title: '關於環球星匯',
    wrapper_title_span1: '環球星匯（Global Consulting Group Limited）是一家領先的國際金融衍生品及相關服務供應商，宗旨是通過監管、極低的交易點差以及傑出的客戶支持服務使投資者進入世界金融市場。我們致力於創建一站式的外匯交易平臺，交易者可以在全球市場上交易所有產品。能夠通過一個賬戶進行外匯，指數，商品，期貨等差價合約交易。讓便利性成爲交易者選擇環球星匯的關鍵因素之一。',
    wrapper_title_span2: '經過多年的深耕累積，足夠豐富的經驗爲對全球金融衍生品市場感興趣的投資人打造一個安全、透明的交易環境。與頂尖的流動性供應商強強聯合，通過在線交易平臺實時交易各種金融衍生品，爲投資者提供極具競爭力的交易點差，降低交易中的風險。',
    wrapper_title_span3: '環球星匯採用先進的在線交易系統涵蓋專業的外匯和差價合約市場，幫助客戶通過風險管理體系獲得快速準確的報價。環球星匯團隊成員具備多年的交易和合規經驗，從交易者和銷售雙方獲得經紀商業界知識，結合敏銳的市場洞察力，使我們能夠在不斷變化的市場中瞭解客戶的需求和更好的接受挑戰，爲交易者提供安全、穩定、透明的交易平臺。',
    wrapper_title2: '監管與安全',
    wrapper_title2_span1: '我們十分重視用戶的投資與資金安全，環球星匯由美國MSB（MoneyServicesBusiness）監管並頒發的金融牌照許可（許可證號爲31000280998905），嚴格執行其資金風險管理措施。',
    // wrapper_sub_title: '知識是明智的投資',    
    p2: '我們一直堅信您認知得越多，將成就更好的你。通過簡易理解的信息與可執行的見解互相結合，環球星匯有助於使市場看起來不那麼令人生畏，並更易被接受。 MARKETS有助于使市场看起来不那么令人生畏，并更易被接受。',
    info_title1: '初學者課程',
    info_span1: '學習外匯交易的基本概念、這個市場的概括和您理應成爲一份子的原因。',
    info_title2: '交易工具',
    info_span2: '熟悉先進的策略和Xtrade的交易工具， 使您的交易提升至另一個新層次。',
    info_title3: '股票和差價合約',
    info_span3: '探索差價合約交易的世界：差價合約市場的相關信息和市場動態。',
  }  
}