export default {
  contact: {
    p1: '知识赋予您力量',
    wrapper_title1: '合作模式',
    p2: '环球星汇将竭诚为合作伙伴申请给予交易平台的教程，并提供相应的代理商网站为您提供一系列的免费服务支持。',
    info_title1: '合作伙伴',
    info_span1: '欲咨询现有或潜在的有限合伙人/投资者的信息',
    info_title2: '业务支持',
    info_span2: '欲咨询培训＋销售＋支持方案的信息',
    info_title3: '商业计划',
    info_span3: '期望建立自主品牌社区平台',
    email_btn: '请电邮至： info@gcg-markets.com'
  }  
}