import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Local } from '@/utils/storage';

import zhcnLocale from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhtwLocale from 'element-ui/lib/locale/lang/zh-TW';

import nextZhcn from '@/i18n/lang/zh-cn.js';
import nextEn from '@/i18n/lang/en.js';
import nextZhtw from '@/i18n/lang/zh-tw.js';

import pagesHomeZhcn from '@/i18n/pages/home/zh-cn.js';
import pagesHomeEn from '@/i18n/pages/home/en.js';
import pagesHomeZhtw from '@/i18n/pages/home/zh-tw.js';

import pagesInvestmentZhcn from '@/i18n/pages/investment/zh-cn.js';
import pagesInvestmentEn from '@/i18n/pages/investment/en.js';
import pagesInvestmentZhtw from '@/i18n/pages/investment/zh-tw.js';

import pagesEducationZhcn from '@/i18n/pages/education/zh-cn.js';
import pagesEducationEn from '@/i18n/pages/education/en.js';
import pagesEducationZhtw from '@/i18n/pages/education/zh-tw.js';

import pagesContactZhcn from '@/i18n/pages/contact/zh-cn.js';
import pagesContactEn from '@/i18n/pages/contact/en.js';
import pagesContactZhtw from '@/i18n/pages/contact/zh-tw.js';




// 使用插件
Vue.use(VueI18n);

// 定义语言国际化内容
/**
 * 说明：
 * /src/i18n/lang 下的 js 为框架的国际化内容
 * /src/i18n/pages 下的 js 为各界面的国际化内容
 */
 const messages = {
	zh: {
		...zhcnLocale,
		message: {
			...nextZhcn
		},
		...pagesHomeZhcn,
		...pagesInvestmentZhcn,
		...pagesEducationZhcn,
		...pagesContactZhcn
	},
	en: {
		...enLocale,
		message: {
			...nextEn
		},
		...pagesHomeEn,
		...pagesInvestmentEn,
		...pagesEducationEn,
		...pagesContactEn
	},
	hk: {
		...zhtwLocale,		
		message: {
			...nextZhtw,
		},
		...pagesHomeZhtw,
		...pagesInvestmentZhtw,
    ...pagesEducationZhtw,
		...pagesContactZhtw
	},
};

// 导出语言国际化
export const i18n = new VueI18n({
	locale: Local.get('lang') ? Local.get('lang').lang : 'zh',
	fallbackLocale: 'zh',
	messages,
});

// console.log(i18n.tc("message.app.youLoggedOut"))
// 重新封装方法
export function $t(args) {
	return i18n.tc.call(i18n, args);
}
