export default {
  home: {
    notWait: "Don't wait.",
    openAccountToday: 'Open an account today.',
    openAccount: 'Open an account',
    GCGMarkets: '环球星汇',
    lowRisk: 'Low risk with insurance trading with confidence',
    exclusive: 'Exclusive cutting-edge technology',
    trading: 'Trading warehouse Safe warehouse perfect operation',
    realize: 'Realize your trading dreams',
    wrapper_title1: 'Many ways to invest, one place to practice.',
    drc_invest_title: 'Value investment',
    drc_invest_span: 'Low transaction fees and pricing, no hidden fees',
    drc_products_title: 'Complete set of products',
    drc_products_span: 'Trade Forex/precious metals',
    drc_tools_title: 'Super power trading tools',
    drc_tools_span: 'A platform that brings trading power to your fingertips',
    drc_partner_title: 'Work with a consultant',
    drc_partner_span: 'Get professional help to create a personalized financial plan',
    register: 'Sign in',
    bestbroker_span1_1: 'Best Online Broker 2018',
    bestbroker_wrapper_title1: 'How to start Forex trading',
    bestbroker_span4: 'You need to register a Forex account and start trading. By comparison, find a Forex trading account that caters to your needs. Currency pairs are used in foreign exchange transactions; The first currency is the base currency and the second currency is the anti-currency. You have two options to generate revenue:',
    bestbroker_span5: 'Buy: If you think the base currency will appreciate more than the anti-currency.',
    bestbroker_span6: 'Sell: If you think the anti-currency will appreciate more than the base currency.',
    bestbroker_span1_2: 'Work with us the way you want.',
    bestbroker_wrapper_title2: 'Your terms of the relationship.',
    bestbroker_text3: "Some people think you have to choose online brokers and wealth managers. At GCG Markets, you don't need to compromise. Whether you invest on your own, as an advisor, or both - we can support you.",
    bestbroker_info_title1: 'Invest',
    bestbroker_info_span1: 'A wide selection of investment products to help build a diversified portfolio',
    bestbroker_info_title2: 'Trade',
    bestbroker_info_span2: 'Superior trading tools, resources and support',
    bestbroker_info_title3: 'Wealth management',
    bestbroker_info_span3: 'Dedicated financial advisors help you reach your own specific goals',
    bestbroker_info_title4: 'Regulation and licensing',
    bestbroker_info_span4: 'A financial license regulated and issued by the MSB',
    bestbroker_info_title5: 'Intelligent combination',
    bestbroker_info_span5: 'A revolutionary fully automated investment advisory service',
    bestbroker_info_title6: 'Consultant network',
    bestbroker_info_span6: 'Independent local advisors provide professional guidance to high net worth investors',
    download_wrapper_title: 'Enter the market with our strong platform.',
    download_text2: 'Our flagship trading platform, designed for all kinds of traders.',
    download_text3: 'Trade on any platform from your desktop or mobile tools with the most powerful and convenient platform.',
    download_info_title1: 'WINDOWS Download',
    download_info_title2: 'OS X and IOS downloads',
    download_info_title3: 'Android download',
    knowledge_wrapper_title: 'A trusted broker',
    knowledge_info_title1: 'Beginner course',
    knowledge_info_span1: 'Understand the forex market and learn the basic concepts of forex trading.',
    knowledge_info_title2: 'Trading instrument',
    knowledge_info_span2: 'Advanced trading strategies and MT4 trading tools to improve your trading level.',
    knowledge_info_title3: 'Technical analysis',
    knowledge_info_span3: "Innovative trading strategy techniques designed to create value and grow value for clients' financial assets.",
    knowledge_info_title4: 'Basic analysis',
    knowledge_info_span4: 'Help customers determine the relative value of the market based on all available data.',
    knowledge_info_title5: 'Risk management',
    knowledge_info_span5: 'Help customers get fast and accurate quotes through the risk management system, and provide a safe, stable, efficient and transparent trading environment.',
  }  
}