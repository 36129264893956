export default {
	router: {
		home: '主页',
		investment: '投资',
		education: '环球星汇',
		contactUs: '联系我们',
		login: '登入',
		download: '下载'
	},
	footer: {
		p1: '过去的表现并不担保未来回报，亦不代表未来表现皆如此。切记投资将涉及风险。',
		emailTo: '电邮至: ',
		serviceEmail: '官方客服邮箱：',
		supportEmail: '技术支持邮箱：',
		copyright: '版权由 2013 Global Consulting Group Limited. 所有，侵权必究。',
		div_p1: '保证金交易是一种高风险的投资活动。杠杆式场外交易的现货外币合约具有投机性和高风险性。它通常只适合那些能够承受损失风险超过其保证金存款的人。鉴于这些风险，客户在进行交易之前，应充分了解所涉及的交易性质和他们面临的风险程度。场外外汇现货交易并不适合部分客户。客户应根据其个人经验、投资目标、资金来源和其他相关情况，仔细考虑此类交易的适用性。您应仔细了解与外汇交易相关的风险。如有任何疑问，请咨询您的专业财务顾问。',
		div_p1_strong: '风险管理',
		div_p2: '环球星汇提供的相关信息及其链接以及来自其他来源的市场信息仅供参考服务之用。环球星汇不推荐来自任何来源或第三方交易分析和指导的财务建议。客户应仔细考虑和分析相关信息或其他信息来源，以便做出客观的分析和决策。请注意，过去的交易结果不是预测未来交易表现的基础。环球星汇建议客户在投资前应仔细了解相关交易知识以及经纪商和其他形式的服务提供商所展示的交易或投资建议。环球星汇网站引用的任何新闻、观点、研究、数据或其他信息仅作为客观的市场评论，不构成任何投资或交易建议。环球星汇特此明确声明，对于因客户直接或间接使用上述任何信息而产生的交易损失或利润，环球星汇概不负责。',
		div_p2_strong: '交易免责声明',
	}
};